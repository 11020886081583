import React, { useState } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import './cancel-why.css';
import Button from '../components/buttons/button';
import { ButtonColor, ButtonStyle } from '../models/button-style';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import axios from 'axios';
import { Route } from '../models/site-links';
import { any } from 'prop-types';

const CancelWhy = ({ location }) => {
  let sessionID;
  let id;
  let giveOffers;
  if (location.state) {
    sessionID = location.state.sessionID;
    id = location.state.id;
    giveOffers = location.state.giveOffers;
  } else if (typeof window !== 'undefined') {
    // navigate to contact support
    navigate(Route.Cancel);
  }

  const [postContent, setPostContent] = useState('');
  const [disabled, setDisabled] = useState(false);

  const formData = {
    accountId: id,
    sessionId: sessionID,
    cancellationDetails: postContent,
  };

  const submitForm = async () => {
    setDisabled(true);

    axios
      .post('https://core.prod.patronscan.servallapps.com/api/v1/cancel/finalize/', formData)
      .then(() => {
        navigate(Route.CancelSuccess);
      })
      .catch((error) => {
        navigate(Route.Cancel);
      });

    setTimeout(() => {
      setDisabled(false);
    }, 1500);
  };

  const offerCheck = () => {
    if (giveOffers == true) {
      return (
        <div className="bottomButtons">
          <AniLink
            fade
            className="block hover:text-grey-dark transition-color lg:text-base industry-picks -mr-6"
            to="/cancel-options"
            state={{ id, sessionID, giveOffers }}
          >
            <Button
              buttonColor={ButtonColor.BlackCancelPrimary}
              buttonStyle={ButtonStyle.RetentionButtonMed}
              text={'Back'}
              buttonId={'BackButton'}
            />
          </AniLink>
          <button
            className="text-lg w-228px py-2.5 my-4 ml-10 cursor-pointer rounded-lg text-error-700 bg-white hover:bg-neutral-50 border-2 border-error-700"
            onClick={(e) => submitForm()}
            disabled={disabled}
          >
            Cancel subscription
          </button>
        </div>
      );
    } else {
      return (
        <div className="bottomButtons">
          <AniLink
            fade
            className="block industry-picks"
            to={'/cancel?id=' + id}
            state={{ id, sessionID }}
          >
            <Button
              buttonColor={ButtonColor.BlackCancelPrimary}
              buttonStyle={ButtonStyle.RetentionButtonMed}
              text={'Back'}
              buttonId={'BackButton'}
            />
          </AniLink>
          <button
            className="text-lg w-228px py-2.5 my-4 ml-10 cursor-pointer rounded-lg text-error-700 bg-white hover:bg-neutral-50 border-2 border-error-700"
            onClick={(e) => submitForm()}
            disabled={disabled}
          >
            Cancel subscription
          </button>
        </div>
      );
    }
  };

  return (
    <div>
      <div className="flex justify-center">
        <div className="mb-20 mt-10">
          <p className="header">Cancel Subscription</p>
          <p className="subheader">
            Before you cancel your subscription, please let us know why you have decided to part
            ways
          </p>
          <div>
            <label>
              <textarea
                className="textbox"
                placeholder="Add a reason"
                value={postContent}
                onChange={(e) => setPostContent(e.target.value)}
              />
            </label>
          </div>
          {offerCheck()}
        </div>
      </div>
    </div>
  );
};

export default CancelWhy;
